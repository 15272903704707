import React from 'react';

import {graphql} from 'gatsby';
import injectSheet from 'react-jss';

import 'dayjs/locale/de';
import dayjs from "dayjs";

import Layout from "../containers/layout";
import Box from "../components/Box";

import GalleryElement from "../components/GalleryElement";

const styles = theme => {
    return {
        element: {
            width: 'calc(100% / 6)',
            [theme.breakpoints.down('xl')]:{
                width: 'calc(100% / 5)',
            },
            [theme.breakpoints.down('lg')]:{
                width: 'calc(100% / 4)',
            },
            [theme.breakpoints.down('sm')]:{
                width: 'calc(100% / 4)',
            },
            [theme.breakpoints.down('xs')]:{
                width: 'calc(100% / 3)',
            },
            [theme.breakpoints.down('xxs')]:{
                width: 'calc(100% / 2)',
            },
            '@media (max-width:399.95px)': {
                width: 'calc(100% / 1)',
            },
        },
    }
};

const GalleryBox = injectSheet(styles)(({galleries, title, classes}) => {
    return (
        <Box
            className={'container'}
            header={title}
            margin={false}
        >
            {
                galleries.map( (gallery, index) => {
                    const {name, title, slug, previewImage, imageCount, event} = gallery;

                    let date = null;
                    if(event){
                        date = dayjs(event.custom_fields.wpcf_veranstaltungsdatum*1000).locale('de');
                    }


                    return (
                        <div className={classes.element}>
                            <GalleryElement
                                key={index}
                                image={previewImage}
                                count={imageCount}
                                name={name}
                                title={title}
                                slug={slug}
                                date={date}
                            />
                        </div>
                    )
                })
            }
        </Box>
    );
});

const GalleryPage = ({data, classes}) => {
    const single = [],
        team = [],
        special = [];
    data.galleries.edges.forEach(({gallery}) => {

            let taxonomy = !gallery.event? {slug: 'rahmenprogram'}: gallery.event.custom_fields.taxonomies.reduce((acc, current) =>
                current.slug==='slam-2018'? acc: current
            );
            switch (taxonomy.slug) {
                case 'rahmenprogram':
                    special.push(gallery);
                    break;
                case 'team':
                    team.push(gallery);
                    break;
                case 'einzel':
                default:
                    single.push(gallery);
            }
        }
    );
    return (
        <Layout
            pageTitle={'Gallery'}
            pageHeader={(<h2>Gallery</h2>)}
        >
            <GalleryBox galleries={single} title="Einzel Wettbewerb"/>
            <GalleryBox galleries={team} title="Team Wettbewerb"/>
            <GalleryBox galleries={special} title="Rahmenprogramm"/>
        </Layout>
    )
};

export default injectSheet(styles)(GalleryPage);

export const query = graphql`
    query GalleryPage {
        galleries: allGallery(sort: {fields: order}) {
            edges {
                gallery: node {
                    name
                    title
                    slug
                    event {
                        custom_fields{
                            wpcf_veranstaltungsdatum
                            
                            taxonomies{
                                slug
                            }
                        }
                    }
                    previewImage {
                        fluid {
                            aspectRatio
                            src
                            srcSet
                            sizes
                            tracedSVG
                        }
                    }
                    imageCount
                }
            }
        }
    }
`;
